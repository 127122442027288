import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { produce } from 'immer';
import jwt from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import { ACCOUNT_URL, API_URL, EDENEX_ACCOUNT_URL } from '../packages/keycloak-client/constants';
import { useLoginState } from './useLoginState';
import { axios } from '../shared/exios';
import { default as baseAxios } from 'axios';
import { deleteCookieByName, getCookieByName, setCookieByName } from '../shared/helpers/controlCookies';
import i18n from 'i18n';

type TUserKC = {
    access_token: string | undefined;
    refresh_token: string | undefined;
    auth_time: number;
    email: string;
    email_verified: boolean;
    sub: string;
    profile: {
        iat: number;
    };
};

type TUserAPI = {
  uid: string;
  account: {
    nickname: string;
    email: string;
    status: "active" | "banned" | "deleted";
    finms_status: "active" | "banned" | null
    coin_status: "active" | "banned" | "moratorium" | null,
    lang: string;
    userId: number;
    uid: string;
    total: number;
    totalFiat: number;
    last_login: string;
  };
};

type TGetUserKC = {
    cookie: any;
    setCookie: any;
};

type TClientCardState = {
    isAuth: boolean;
    isLoading: boolean;
    serverTime: number;
    clientTime: number;
    // userKC: TUserKC;
    userAPI: TUserAPI;
    userFinmsApi: TUserFinms;
    logout: () => void;
    getUserKC: ({cookie, setCookie}: TGetUserKC) => TUserKC | any;
    refreshToken: (config: any) => Promise<void>;
    getServerTime: (accessToken: string) => Promise<any>;
    setServerTime: (value: number) => void;
    setClientTime: (value: number) => void;
    getUserProfileFromAPI: () => Promise<TUserAPI['account']>;
    setIsAuth: (value: boolean) => void;
    getUserProfileFromAPIBirja: () => void;
    setUserFinms: (value: TUserFinms) => void;
    balance: {
        BTC: {
            amount: number | string;
        };
        ETH: {
            amount: number | string;
        };
    },
    total: any;
    totalFiat: any;
};

export type TDecodedToken = {
    acr: string;
    aud: string;
    azo: string;
    email: string;
    email_verified: boolean;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    preferred_username: string;
    scope: string;
    session_state: string;
    sid: string;
    sub: string;
    typ: string;
};

export type TUserFinms = {
    id: number;
    uid: string;
    companyId: number;
    roleId: number;
    username: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    email: string;
    status: string;
    contactInfo: string | null;
    createdAt: string;
    updatedAt: string;
    language: string;
    totalDebt: string;
    totalDebtAllCourseExist: boolean;
    role: {
        id: number;
        name: string;
        description: string;
        companyId: number;
        createdAt: string;
        updatedAt: string;
    };
    company?: {
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
        currencyKey: string;
        avatar: string;
        country: string | null;
        description: string | null;
        currency: {
            id: number;
            companyId: number;
            name: string;
            shortname: string;
            zerosNumber: number;
            status: string;
            type: string;
            createdAt: string | null;
            updatedAt: string | null;
            network: string | null;
        };
    };
};

export const useAuthState = create<TClientCardState>()(
  devtools(
    (set, get) => ({
      isAuth: false,
      isLoading: true,
      serverTime: 0,
      clientTime: 0,
      userAPI: {
        uid: '',
        account: {
          nickname: '',
          email: '',
          status: 'active',
          finms_status: null,
          coin_status: null,
          userId: 0,
          uid: '',
          total: 0,
          lang: '',
          totalFiat: 0,
          last_login: '',
        },
      },
      userFinmsApi: {
        id: 0,
        uid: '',
        companyId: 0,
        roleId: 0,
        username: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        email: '',
        status: '',
        contactInfo: null,
        createdAt: '',
        updatedAt: '',
        language: '',
        totalDebt: '0',
        totalDebtAllCourseExist: false,
        role: {
          id: 0,
          name: '',
          description: '',
          companyId: 0,
          createdAt: '',
          updatedAt: '',
        },
      },
      balance: {
        BTC: {
          amount: 0,
        },
        ETH: {
          amount: 0,
        },
        USDT: {
          amount: 0
        }
      },
      total: '',
      totalFiat: '',
      setIsAuth: (value: boolean) => {
        set(
          produce((draft) => {
            draft.isAuth = value;
          })
        );
      },
      refreshToken: async (config) => {
        try {
          if (!getCookieByName('refresh_token')) throw new Error('No refresh token');
          const res = await baseAxios.post(
            `${ACCOUNT_URL}/server/edenex-account/api/refresh-token`,
            {
              refresh_token: getCookieByName('refresh_token')!.replace(
                'Bearer ',
                ''
              ),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: '',
              },
            }
          );

          try {
            const {access_token, refresh_token} = res!.data?.data;
            const decodedToken: TDecodedToken = jwtDecode(access_token);

            if (config && refresh_token && access_token) {
              setCookieByName('email', decodedToken?.email);
              setCookieByName('uid', decodedToken?.sub);
              setCookieByName('token', access_token);
              setCookieByName('refresh_token', refresh_token);
              axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

              if (typeof config === 'object' && !!Object.keys(config)?.length) {
                return await axios(config);
              }
            }
          } catch (e) {
            console.error('config refreshToken error', e);
          }


                } catch (e) {
                    console.error('refreshToken error', e);
                    useAuthState.getState().logout();
                }
            },
            getServerTime: async (accessToken: string) => {
                try {
                    const res = await axios.get(
                        `${EDENEX_ACCOUNT_URL}/edenex-account/api/server-time`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken?.replace('Bearer ', '')}`,
                            },
                        }
                    );
                    const {
                        data: {server_time},
                    } = res.data;
                    set(
                        produce((draft) => {
                            draft.setServerTime(server_time);
                        })
                    );
                } catch (e) {
                    console.error('getServerTime error', e);
                }
            },
            setServerTime: (value: number) => {
                set(
                    produce((draft) => {
                        draft.serverTime = value;
                    })
                );
            },
            getUserKC: ({cookie, setCookie}: TGetUserKC) => {
                try {
                    const decodedToken: TDecodedToken = jwt(cookie?.token || '');
                    const AccessToken = `Bearer ${cookie?.token?.replace(
                        'Bearer ',
                        ''
                    )}`;

                    setCookie('email', decodedToken?.email);
                    setCookie('uid', decodedToken?.sub);
                    setCookie('token', `${cookie?.token?.replace('Bearer ', '')}`);
                    setCookie('refresh_token', `${cookie?.refresh_token}`);
                    setCookie('email_verified', localStorage.getItem('email_verified'));
                    setCookie('fromEdenex', true);
                    axios.defaults.headers.common['Authorization'] = AccessToken;

                    get().getServerTime(AccessToken);
                    get().getUserProfileFromAPI();

                    return {
                        access_token: cookie?.token,
                        refresh_token: cookie?.refresh_token,
                        email_verified: decodedToken?.email_verified,
                        uid: decodedToken?.sub,
                    };
                } catch (e) {
                    console.error(e);
                }
            },

            setClientTime: (value: number) => {
                set(
                    produce((draft) => {
                        draft.clientTime += value;
                    })
                );
            },
            logout: async () => {
                useLoginState.getState().setStep(0);
                localStorage.clear();

                deleteCookieByName('token');
                deleteCookieByName('refresh_token');
                deleteCookieByName('uid');
                deleteCookieByName('email');
                deleteCookieByName('remainingTime');
                deleteCookieByName('refreshTokenTimestamp');
                deleteCookieByName('currentServerTime');
                deleteCookieByName('inviteToken');
                deleteCookieByName('email_verified');
                deleteCookieByName('inviteLink');
                deleteCookieByName('fromEdenex');
                deleteCookieByName('fromFinms');
                deleteCookieByName('fromPortal');
                deleteCookieByName('currentPointId');
                deleteCookieByName('channels');
                deleteCookieByName('currentServerTime');
                deleteCookieByName('partnerChatToken');
                deleteCookieByName('phexChatToken');
                deleteCookieByName('refreshTokenTimestamp');
                deleteCookieByName('wsChatToken');

                set(
                    produce((draft) => {
                        draft.isAuth = false;
                    })
                );
            },

            getUserProfileFromAPIBirja: async () => {
                const response = await axios.get(`${API_URL}/account/api/me`);
                if (!!response) {
                    set(
                        produce((draft) => {
                            draft.balance = response?.data?.balance;
                            draft.total = response?.data?.total;
                            draft.totalFiat = response?.data?.total_fiat;
                        }),
                        false,
                        {
                            type: 'useAuthStore => getUserProfileFromAPI',
                        }
                    );
                }
            },

            getUserProfileFromAPI: async () => {
                const response = await axios.get<TUserAPI['account']>(
                    `${EDENEX_ACCOUNT_URL}/edenex-account/api/me`
                );
                const exchangeResponse = await axios.get(`${API_URL}/account/api/me`);
                if (!!response) {
                    i18n.changeLanguage(response?.data?.lang);
                    setCookieByName('i18nLang', response?.data?.lang);
                    set(
                        produce((draft) => {
                          draft.userAPI.account = {...draft.userAPI.account, ...response.data};
                            draft.userAPI.account.total = exchangeResponse.data.total;
                        }),
                        false,
                        {
                            type: 'useAuthStore => getUserProfileFromAPI',
                        }
                    );
                }
                return response.data;
            },
            setUserFinms: async (value) =>
                set(
                    produce((draft) => {
                        draft.userFinmsApi = value;
                    })
                ),
        }),
        {
            anonymousActionType: 'useAuthState action',
            name: 'useClientStateV2',
        }
    )
);
